export default [
  {
    path: "/cars_on_sale",
    name: "cars_on_sale",
    component: () => import("@/views/dealer_views/CarOnSale.vue"),
  },
  {
    path: "/cars_on_sale/:id",
    name: "cars_on_sale_detail",
    component: () => import("@/views/dealer_views/AutoDetail.vue"),
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/dealer_views/Orders.vue"),
  },
  {
    path: "/announcements/create",
    name: "announcements_create",
    component: () => import("@/views/dealer_views/CreateAnnouncement.vue"),
  },
  {
    path: "/announcements",
    name: "announcements",
    component: () => import("@/views/dealer_views/Announcements.vue"),
  },
  {
    path: "/reserved_cars",
    name: "reserved_cars",
    component: () => import("@/views/dealer_views/ReservedCars.vue"),
  },
  {
    path: "/favorites",
    name: "favorite_cars",
    component: () => import("@/views/dealer_views/FavoriteCars.vue"),
  },
  {
    path: "/support",
    name: "support",
    component: () => import("@/views/dealer_views/Support.vue"),
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("@/views/dealer_views/Cart.vue"),
  },
];
