import AxiosIns from "@/libs/axios";
import VueCookies from "vue-cookies";
import { CornerDownLeftIcon } from "vue-feather-icons";

AxiosIns.interceptors.request.use((config) => {
  const user_token = VueCookies.get("access_token");
  if (user_token != null) {
    config.headers.Authorization = "Bearer " + user_token;
  }
  const workspace = JSON.parse(localStorage.getItem("workspace"));
  if (workspace && workspace.workspace_id) {
    if (!config.data) {
      config.data = {};
    }
    if (config.data instanceof FormData) {
      config.data.append('workspace_id', workspace.workspace_id);
    } else {
      config.data.workspace_id = workspace.workspace_id;
    }
  }
  return config;
});

export class Requests {
  //Login

  static async login(data) {
    const response = await AxiosIns.post("/login", data);
    return response.data[0];
  }

  //Recupero Password

  static async passwordRecovery(data) {
    const response = await AxiosIns.post("/lost_password", data);
    return response.data[0];
  }

  //Dati utente in sessione

  static async getLoggedUser() {
    const response = await AxiosIns.post("/me", null);
    return response.data[0];
  }

  //Modifica Dati Auto

  static async autoPatch(data) {
    const response = await AxiosIns.post("/db_auto_patch", data);
    return response.data;
  }

  //Cerca Auto

  static async searchVehicle(data) {
    const response = await AxiosIns.post("/db_auto_search", data);
    return response.data;
  }


  static async getAutoPhotos(data) {
    const response = await AxiosIns.post("/db_auto_photos", data);
    return response.data;
  }

  static async getAutoExaminationPhotos(data) {
    const response = await AxiosIns.post("/db_auto_examination_photos", data);
    return response.data;
  }

  //Ditte

  static async getBusiness() {
    const response = await AxiosIns.post("/tbl_ditta_datatable", null);
    return response.data;
  }

  //Marche Auto

  static async getCarBrand() {
    const response = await AxiosIns.post(
      "/component_casemadri_datatable",
      null
    );
    return response.data;
  }

  //Modelli Auto Per Marca

  static async getCarModelByBrand(data) {
    const response = await AxiosIns.post(
      "/component_modello_from_casamadre",
      data
    );
    return response.data;
  }

  //Tipologie Pratiche

  static async getPractices(data) {
    const response = await AxiosIns.post("/tbl_pratices_type_datatable", data);
    return response.data;
  }

  //Aggiungi Tipologia Pratica

  static async addPractice(data) {
    const response = await AxiosIns.post("/tbl_pratices_type_addnew", data);
    return response.data;
  }

  //Modifica Tipologia Pratica

  static async editPractice(data) {
    const response = await AxiosIns.post("/tbl_pratices_type_edit", data);
    return response.data;
  }

  //Agenzie Partner

  static async getPartners(data) {
    const response = await AxiosIns.post(
      "/tbl_pratices_agency_datatable",
      data
    );
    return response.data;
  }

  //Aggiungi Agenzia Partner

  static async addPartner(data) {
    const response = await AxiosIns.post("/tbl_pratices_agency_addnew", data);
    return response.data;
  }

  //Modifica Agenzia Partner

  static async editPartner(data) {
    const response = await AxiosIns.post("/tbl_pratices_agency_edit", data);
    return response.data;
  }

  //Finanziarie

  static async getFinancial(data) {
    const response = await AxiosIns.post(
      "/tbl_pratices_financial_datatable",
      data
    );
    return response.data;
  }

  //Aggiungi Finanziaria

  static async addFinancial(data) {
    const response = await AxiosIns.post(
      "/tbl_pratices_financial_addnew",
      data
    );
    return response.data;
  }

  //Modifica Finanziaria

  static async editFinancial(data) {
    const response = await AxiosIns.post("/tbl_pratices_financial_edit", data);
    return response.data;
  }

  //Ubicazioni

  static async getLocations(data) {
    const response = await AxiosIns.post("/tbl_ubicazione_datatable", data);
    return response.data;
  }

  //Aggiungi Ubicazione

  static async addLocation(data) {
    const response = await AxiosIns.post("/tbl_ubicazione_addnew", data);
    return response.data;
  }

  static async editLocation(data) {
    const response = await AxiosIns.post("/tbl_ubicazione_edit", data);
    return response.data;
  }


  //Foto Auto

  static async getVehiclePhotos(data) {
    const response = await AxiosIns.post("/db_foto_details", data);
    return response.data;
  }

  //Foto Perizia Auto

  static async getVehiclePeriziaPhotos(data) {
    const response = await AxiosIns.post("/db_foto_perizia_details", data);
    return response.data;
  }

  //Prenotazioni Auto

  static async getVehicleReservations(data) {
    const response = await AxiosIns.get(
      "/db_reservation_datatable?id_auto=" + data
    );
    return response.data;
  }

  //Datatable Auto Da Pubblicare

  static async getAutoToPublish(data) {
    const response = await AxiosIns.post("/db_auto_datatable_to_publish", data);
    return response.data;
  }

  //Datatable Auto In Arrivo

  static async getAutoIncoming() {
    const response = await AxiosIns.post("/db_auto_datatable_incoming", null);
    return response.data;
  }

  //Datatable Auto Riservate

  static async getAutoReserved() {
    const response = await AxiosIns.post("/db_auto_datatable_reserved", null);
    return response.data;
  }

  //Datatable Auto In Vendita

  static async getAutoOnSale(data) {
    const response = await AxiosIns.post("/db_auto_datatable_on_sale", data);
    return response.data;
  }

  //Datatable Auto Vendute

  static async getAutoSold(data) {
    const response = await AxiosIns.post("/db_auto_datatable_sold", data);
    return response.data;
  }

  //Datatable Auto Da Verificare

  static async getAutoToVerify(data) {
    const response = await AxiosIns.post("/db_auto_datatable_to_verify", data);
    return response.data;
  }

  //Datatable Auto CarStock

  static async getCarStock() {
    const response = await AxiosIns.post("/db_carstock_datatable", null);
    return response.data;
  }

  //Dettagli Auto

  static async getAutoDetails(data) {
    const response = await AxiosIns.post("/db_auto_details", data);
    return response.data;
  }

  //Crea CarStock

  static async createCarStock(data) {
    const response = await AxiosIns.post("/db_carstock_addnew", data);
    return response.data;
  }

  //Elimina CarStock

  static async deleteCarStock(data) {
    const response = await AxiosIns.post("/db_carstock_delete", data);
    return response.data;
  }

  //Dettagli CarStock

  static async getCarStockDetail(data) {
    const response = await AxiosIns.post("/db_carstock_details", data);
    return response.data[0];
  }

  //Elenco Fornitori

  static async getSuppliersList(data) {
    const response = await AxiosIns.post("/db_supplier_datatable", data);
    return response.data;
  }

  //Aggiunta Fornitore

  static async addSupplier(data) {
    const response = await AxiosIns.post("/db_supplier_addnew", data);
    return response.data;
  }

  //Dettaglio Fornitore

  static async getSupplierDetails(data) {
    const response = await AxiosIns.post("/db_supplier_details", data);
    return response.data;
  }

  //Elenco Clienti

  static async getCustomerList(data) {
    const response = await AxiosIns.post("/db_customer_datatable", data);
    return response.data;
  }

  //Dettaglio Cliente

  static async getCustomerDetails(data) {
    const response = await AxiosIns.post("/db_customer_details", data);
    return response.data;
  }

  //Modifica Cliente

  static async editCustomerDetails(data) {
    const response = await AxiosIns.post("/db_customer_edit", data);
    return response.data;
  }

  //Elenco Account

  static async getAccountList(data) {
    const response = await AxiosIns.post("/db_user_datatable", data);
    return response.data;
  }

  //Creazione Account

  static async addAccount(data) {
    const response = await AxiosIns.post("/db_user_addnew", data);
    return response.data;
  }

  //Elenco Rivenditori

  static async getResellers() {
    const response = await AxiosIns.post("/db_reseller_datatable", null);
    return response.data;
  }

  //Elenco Campagne

  static async getCampaignList(data) {
    const response = await AxiosIns.post("/newsletter_content_datatable", data);
    return response.data;
  }

  //Elenco Mittenti Campagne

  static async getSendersList(data) {
    const response = await AxiosIns.post("/newsletter_senders_datatable", data);
    return response.data;
  }

  //Elenco Liste Destinatari

  static async getRecipientList(data) {
    const response = await AxiosIns.post(
      "/newsletter_userlists_datatable",
      data
    );
    return response.data;
  }

  //Dettaglio Liste Destinatari

  static async getRecipientDetails(data) {
    const response = await AxiosIns.post("/newsletter_userlists_details", data);
    return response.data;
  }

  //Elenco Template

  static async getTemplatesList(data) {
    const response = await AxiosIns.post("/newsletter_layout_datatable", data);
    return response.data;
  }

  //Elenco Preventivi

  static async getEstimatesList() {
    const response = await AxiosIns.post("/customer_estimate_datatable", null);
    return response.data;
  }

  //Elenco contratti privati

  static async getContracts(status) {
    const response = await AxiosIns.get(
      "/db_orders_datatable?status=" + status
    );
    return response.data;
  }

  //Elenco Leads

  static async getLeadsList() {
    const response = await AxiosIns.post("/crm_database_datatable", null);
    return response.data;
  }

  //Dettaglio Leads

  static async getLeadDetails(data) {
    const response = await AxiosIns.post("/crm_database_details", data);
    return response.data;
  }

  //Elenco Servizi

  static async getServices(data) {
    const response = await AxiosIns.post("/component_services_datatable", data);
    return response.data;
  }

  //Aggiunta Servizio

  static async addService(data) {
    const response = await AxiosIns.post("/component_services_addnew", data);
    return response.data;
  }

  //Modifica Servizio

  static async editService(data) {
    const response = await AxiosIns.post("/component_services_edit", data);
    return response.data;
  }

  //Elenco Accessori

  static async getAccessories(data) {
    const response = await AxiosIns.post(
      "/component_accessories_datatable",
      data
    );
    return response.data;
  }

  //Aggiunta Accessorio

  static async addAccessory(data) {
    const response = await AxiosIns.post("/component_accessories_addnew", data);
    return response.data;
  }

  //Modifica Accessorio

  static async editAccessory(data) {
    const response = await AxiosIns.post("/component_accessories_edit", data);
    return response.data;
  }

  //Elenco Avvisi

  static async getAnnouncements(data) {
    const response = await AxiosIns.post("/customer_notify_datatable", data);
    return response.data;
  }

  //Elenco Avvisi Reseller

  static async getResellerAnnouncements(data) {
    const response = await AxiosIns.post("/reseller_notify_datatable", data);
    return response.data;
  }

  //Creazione Avviso Reseller

  static async createAnnouncement(data) {
    const response = await AxiosIns.post("/reseller_notify_addnew", data);
    return response.data;
  }

  //Rimuovi Avviso Reseller

  static async deleteAnnouncement(data) {
    const response = await AxiosIns.post("/reseller_notify_delete", data);
    return response.data;
  }

  //Aggiungi auto al carrello

  static async addToCart(data) {
    const response = await AxiosIns.post("/cart_add_item", data);
    return response.data;
  }

  //Rimuovi auto dal carrello

  static async removeFromCart(data) {
    const response = await AxiosIns.post("/cart_remove_item", data);
    return response.data;
  }

  //Dati Carrello

  static async getCartItems(data) {
    const response = await AxiosIns.post("/cart_items", data);
    return response.data;
  }

  //Conclusione Ordine

  static async orderCreate() {
    const response = await AxiosIns.post("/db_order_requests_create", null);
    return response.data;
  }

  //Auto Preferite

  static async getFavoriteCars() {
    const response = await AxiosIns.post("/tbl_bookmark_items", null);
    return response.data;
  }

  //Aggiungi Auto ai Preferiti

  static async addToFavorites(data) {
    const response = await AxiosIns.post("/tbl_bookmark_addnew", data);
    return response.data;
  }

  static async getMyWorkspaces(data) {
    const response = await AxiosIns.post("/my_workspaces", data);
    return response.data;
  }
}
