import { Ability } from "@casl/ability";
import {
  initialAbility,
  managerAbility,
  dealerAbility,
  sellerAbility,
} from "./config";

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem("user"));

export function getUserRole(user) {
  if (user) {
    switch (user.account_type) {
      case "manager": {
        return managerAbility;
      }

      case "dealer": {
        return dealerAbility;
      }

      case "seller": {
        return sellerAbility;
      }
    }
  } else {
    return initialAbility;
  }
}

const abilities = getUserRole(userData);

export default new Ability(abilities);
