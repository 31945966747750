const cart = {
  state: {
    cart: 0,
  },

  mutations: {
    setCart(state, cart) {
      state.cart = cart;
    },

    addItem(state) {
      state.cart += 1;
    },

    removeItem(state) {
      state.cart -= 1;
    },
  },

  actions: {
    setCart({ commit }, cart) {
      commit("setCart", cart);
    },

    addItem({ commit }, cart) {
      commit("addItem", cart);
    },

    removeItem({ commit }, cart) {
      commit("removeItem", cart);
    },
  },
};

export default cart;
