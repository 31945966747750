import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import dealer_routes from "./dealer-routes";

import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: { name: "login" },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        redirectIfLoggedIn: true,
        resource: "Auth",
        layout: "full",
      },
    },
    {
      path: "/workspaces",
      name: "workspaces",
      component: () => import("@/views/Workspaces.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/workspaces/create",
      name: "workspaces_create",
      component: () => import("@/views/CreateWorkspace.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pricing",
      name: "pricing",
      component: () => import("@/views/Pricing.vue"),
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/Dashboard.vue"),
      meta: {
        resource: "Auth",
      },
    },
    {
      path: "/dealer/dashboard",
      name: "dealer_dashboard",
      component: () => import("@/views/dealer_views/Dashboard.vue"),
    },
    {
      path: "/search_car",
      name: "search_car",
      component: () => import("@/views/SearchAuto.vue"),
    },
    {
      path: "/car_fleet/add_car",
      name: "carfleet_addcar",
      component: () => import("@/views/CarFleet/AddCar.vue"),
    },
    {
      path: "/car_fleet/eurotax/login",
      name: "carfleet_login_eurotax",
      component: () => import("@/views/CarFleet/LoginEuroTax.vue"),
    },
    {
      path: "/car_fleet/eurotax/add_car",
      name: "carfleet_addcar_eurotax",
      component: () => import("@/views/CarFleet/AddCarEuroTax.vue"),
    },
    {
      path: "/car_fleet/to_publish",
      name: "carfleet_topublish",
      component: () => import("@/views/CarFleet/ToPublish.vue"),
    },
    {
      path: "/car_fleet/on_sale",
      name: "carfleet_onsale",
      component: () => import("@/views/CarFleet/OnSale.vue"),
    },
    {
      path: "/car_fleet/sold",
      name: "carfleet_sold",
      component: () => import("@/views/CarFleet/Sold.vue"),
    },
    {
      path: "/car_fleet/reserved",
      name: "carfleet_reserved",
      component: () => import("@/views/CarFleet/Reserved.vue"),
    },
    {
      path: "/car_fleet/incoming",
      name: "carfleet_incoming",
      component: () => import("@/views/CarFleet/Incoming.vue"),
    },
    {
      path: "/car_fleet/to_verify",
      name: "carfleet_toverify",
      component: () => import("@/views/CarFleet/ToVerify.vue"),
    },
    {
      path: "/car_fleet/on_sale/private/list",
      name: "seller_carfleet_sale_privates",
      component: () =>
        import("@/views/seller_views/CarFleet/OnSaleToPrivates.vue"),
    },
    {
      path: "/car_fleet/on_sale/estimate/customer",
      name: "seller_carfleet_estimate_customer",
      component: () =>
        import("@/views/seller_views/CarFleet/CustomerSearch.vue"),
    },
    {
      path: "/car_fleet/on_sale/estimate/create",
      name: "seller_carfleet_estimate_create",
      component: () =>
        import("@/views/seller_views/CarFleet/CreateEstimate.vue"),
    },
    {
      path: "/car_fleet/on_sale/reseller/list",
      name: "seller_carfleet_sale_resellers",
      component: () =>
        import("@/views/seller_views/CarFleet/OnSaleToResellers.vue"),
    },
    {
      path: "/carstock/create",
      name: "carstock_create",
      component: () => import("@/views/Carstock/CreateCarstock.vue"),
    },
    {
      path: "/carstock/list",
      name: "carstock_list",
      component: () => import("@/views/Carstock/ListCarstock.vue"),
    },
    {
      path: "/carstock/:id",
      name: "carstock_detail",
      component: () => import("@/views/Carstock/DetailCarstock.vue"),
    },
    {
      path: "/multipublishing/vehicles",
      name: "multipublishing_vehicles",
      component: () => import("@/views/Multipublishing/SelectVehicle.vue"),
    },
    {
      path: "/multipublishing/channels",
      name: "multipublishing_channels",
      component: () => import("@/views/Multipublishing/PublishingChannels.vue"),
    },
    {
      path: "/suppliers/list",
      name: "suppliers_list",
      component: () => import("@/views/Suppliers/Suppliers.vue"),
    },
    {
      path: "/suppliers/add",
      name: "suppliers_add",
      component: () => import("@/views/Suppliers/CreateSuppliers.vue"),
    },
    {
      path: "/suppliers/:mode/:id",
      name: "suppliers_details",
      component: () => import("@/views/Suppliers/SupplierDetails.vue"),
    },
    {
      path: "/customer/list",
      name: "customer_list",
      component: () => import("@/views/Customers/CustomerList.vue"),
    },
    {
      path: "/customer/search",
      name: "customer_search",
      component: () => import("@/views/Customers/CustomerSearch.vue"),
    },
    {
      path: "/customer/add",
      name: "customer_add",
      component: () => import("@/views/Customers/CustomerAdd.vue"),
    },
    {
      path: "/customer/:id/:edit?",
      name: "customer_detail",
      component: () => import("@/views/Customers/CustomerDetail.vue"),
    },
    {
      path: "/account/manager/list",
      name: "account_manager_list",
      component: () =>
        import("@/views/AccountManagement/AccountManagerList.vue"),
    },
    {
      path: "/account/manager/add",
      name: "account_manager_add",
      component: () =>
        import("@/views/AccountManagement/AccountManagerAdd.vue"),
    },
    {
      path: "/account/seller/list",
      name: "account_seller_list",
      component: () =>
        import("@/views/AccountManagement/AccountSellerList.vue"),
    },
    {
      path: "/account/seller/add",
      name: "account_seller_add",
      component: () => import("@/views/AccountManagement/AccountSellerAdd.vue"),
    },
    {
      path: "/account/logistics/list",
      name: "account_logistics_list",
      component: () =>
        import("@/views/AccountManagement/AccountLogisticsList.vue"),
    },
    {
      path: "/account/logistics/add",
      name: "account_logistics_add",
      component: () =>
        import("@/views/AccountManagement/AccountLogisticsAdd.vue"),
    },
    {
      path: "/account/backoffice/list",
      name: "account_backoffice_list",
      component: () =>
        import("@/views/AccountManagement/AccountBackofficeList.vue"),
    },
    {
      path: "/account/backoffice/add",
      name: "account_backoffice_add",
      component: () =>
        import("@/views/AccountManagement/AccountBackofficeAdd.vue"),
    },
    {
      path: "/resellers/list",
      name: "resellers_list",
      component: () => import("@/views/Resellers/Resellers.vue"),
    },
    {
      path: "/resellers/add",
      name: "resellers_add",
      component: () => import("@/views/Resellers/ResellerAdd.vue"),
    },
    {
      path: "/resellers/:mode/:id",
      name: "resellers_details",
      component: () => import("@/views/Resellers/ResellerDetails.vue"),
    },
    {
      path: "/other_reports/load_unload",
      name: "other_reports_load_unload",
      component: () => import("@/views/OtherReports/LoadUnload.vue"),
    },
    {
      path: "/other_reports/exposure",
      name: "other_reports_exposure",
      component: () => import("@/views/OtherReports/Exposure.vue"),
    },
    {
      path: "/other_reports/car_in_stock",
      name: "other_reports_car_in_stock",
      component: () => import("@/views/OtherReports/CarInStock.vue"),
    },
    {
      path: "/newsletter/campaign/list",
      name: "newsletter_campaign_list",
      component: () => import("@/views/Newsletter/CampaignList.vue"),
    },
    {
      path: "/newsletter/campaign/create",
      name: "newsletter_campaign_create",
      component: () => import("@/views/Newsletter/CampaignCreate.vue"),
    },
    {
      path: "/newsletter/recipient/list",
      name: "newsletter_recipient_list",
      component: () => import("@/views/Newsletter/RecipientList.vue"),
    },
    {
      path: "/newsletter/recipient/:id",
      name: "newsletter_recipient_details",
      component: () => import("@/views/Newsletter/RecipientDetails.vue"),
    },
    {
      path: "/newsletter/template/list",
      name: "newsletter_template_list",
      component: () => import("@/views/Newsletter/TemplateList.vue"),
    },
    {
      path: "/newsletter/template/create",
      name: "newsletter_template_create",
      component: () => import("@/views/Newsletter/TemplateCreateAuto.vue"),
    },
    {
      path: "/newsletter/template/editor",
      name: "newsletter_template_editor",
      component: () => import("@/views/Newsletter/TemplateCreateEditor.vue"),
    },
    {
      path: "/newsletter/stats",
      name: "newsletter_statistics",
      component: () => import("@/views/Newsletter/Statistics.vue"),
    },
    {
      path: "/report/access",
      name: "report_access",
      component: () => import("@/views/Report/AccessReports.vue"),
    },
    {
      path: "/report/auto",
      name: "report_auto",
      component: () => import("@/views/Report/AutoReports.vue"),
    },
    {
      path: "/report/sales",
      name: "report_sales",
      component: () => import("@/views/Report/SalesReports.vue"),
    },
    {
      path: "/report/sales/sellers",
      name: "report_seller_sales",
      component: () => import("@/views/Report/SellerSalesReports.vue"),
    },
    {
      path: "/report/buy/sellers",
      name: "report_seller_buy",
      component: () => import("@/views/Report/SellerBuyReports.vue"),
    },
    {
      path: "/estimates/list",
      name: "estimates_list",
      component: () => import("@/views/Estimates.vue"),
    },
    {
      path: "/contract/private/approved/list",
      name: "contracts_private_approved_list",
      component: () => import("@/views/PrivateContracts/ApprovedContracts.vue"),
    },
    {
      path: "/contract/private/pending/list",
      name: "contracts_private_pending_list",
      component: () => import("@/views/PrivateContracts/PendingContracts.vue"),
    },
    {
      path: "/contract/private/denied/list",
      name: "contracts_private_denied_list",
      component: () => import("@/views/PrivateContracts/DeniedContracts.vue"),
    },
    {
      path: "/contract/reseller/approved/list",
      name: "contracts_reseller_approved_list",
      component: () =>
        import("@/views/ResellerContracts/ApprovedContracts.vue"),
    },
    {
      path: "/contract/reseller/pending/list",
      name: "contracts_reseller_pending_list",
      component: () => import("@/views/ResellerContracts/PendingContracts.vue"),
    },
    {
      path: "/contract/reseller/denied/list",
      name: "contracts_reseller_denied_list",
      component: () => import("@/views/ResellerContracts/DeniedContracts.vue"),
    },
    {
      path: "/crm/agenda",
      name: "crm_agenda",
      component: () => import("@/views/Crm/Agenda.vue"),
    },
    {
      path: "/crm/leads",
      name: "crm_leads",
      component: () => import("@/views/Crm/Leads.vue"),
    },
    {
      path: "/crm/leads/:id",
      name: "crm_leads_details",
      component: () => import("@/views/Crm/LeadsDetails.vue"),
    },
    {
      path: "/crm/activities",
      name: "crm_activities",
      component: () => import("@/views/Crm/Activities.vue"),
    },
    {
      path: "/crm/negotiations",
      name: "crm_negotiations",
      component: () => import("@/views/Crm/Negotiations.vue"),
    },
    {
      path: "/settings/services",
      name: "settings_services",
      component: () => import("@/views/Settings/ServicesManagement.vue"),
    },
    {
      path: "/settings/accessories",
      name: "settings_accessories",
      component: () => import("@/views/Settings/AccessoriesManagement.vue"),
    },
    {
      path: "/settings/partners",
      name: "settings_partners",
      component: () => import("@/views/Settings/PartnersManagement.vue"),
    },
    {
      path: "/settings/practices",
      name: "settings_practices",
      component: () => import("@/views/Settings/PracticesManagement.vue"),
    },
    {
      path: "/settings/financial",
      name: "settings_financial",
      component: () => import("@/views/Settings/FinancialManagement.vue"),
    },
    {
      path: "/settings/locations",
      name: "settings_locations",
      component: () => import("@/views/Settings/LocationsManagement.vue"),
    },
    {
      path: "/chat",
      name: "chat",
      component: () => import("@/views/Chat/Chat.vue"),
      meta: {
        contentRenderer: "sidebar-left",
        contentClass: "chat-application",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    ...dealer_routes,
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (!canNavigate(to)) {
    //Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "login" });
  }

  //Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next({ name: "dashboard" });
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
