const initialAbility = [
  {
    action: "read",
    subject: "Auth",
  },
];

const managerAbility = [
  {
    action: "read",
    subject: "Auth",
  },
  {
    action: "read",
    subject: "shared",
  },
  {
    action: "read",
    subject: "manager",
  },
];

const dealerAbility = [
  {
    action: "read",
    subject: "Auth",
  },
  {
    action: "read",
    subject: "dealer",
  },
];

const sellerAbility = [
  {
    action: "read",
    subject: "Auth",
  },
  {
    action: "read",
    subject: "shared",
  },
  {
    action: "read",
    subject: "seller",
  },
];

export { initialAbility, managerAbility, dealerAbility, sellerAbility };

export const _ = undefined;
